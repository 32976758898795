import $ from "jquery";
import React, { Component } from "react";
import ReactGA from "react-ga";
import "./App.css";
import Header from "./Components/Header";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      foo: "bar",
      resumeData: {},
    };
    const navigatorLanguage = navigator.language || navigator.userLanguage;
    this.resumeFile =
      navigatorLanguage === "pt-BR" || navigatorLanguage === "pt"
        ? "resumeData-pt.json"
        : "resumeData.json";

    ReactGA.initialize("G-XKS3ZT6Z94", {
      gaOptions: { cookieFlags: "SameSite=None; Secure" },
    });
    ReactGA.pageview(window.location.pathname);
  }

  getResumeData() {
    $.ajax({
      url: this.resumeFile,
      dataType: "json",
      cache: false,
      success: function (data) {
        this.setState({ resumeData: data });
      }.bind(this),
      error: function (xhr, status, err) {
        console.log(err);
        alert(err);
      },
    });
  }

  componentDidMount() {
    this.getResumeData();
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.resumeData.main} />
      </div>
    );
  }
}

export default App;
