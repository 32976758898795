import React, { Component } from "react";

class Header extends Component {
  render() {
    if (this.props.data) {
      var greeting = this.props.data.name;
      var occupation = this.props.data.occupation;
      var preoccupation = this.props.data.preoccupation;
      var description = this.props.data.description;
      var networks = this.props.data.social.map(function (network) {
        return (
          <li key={network.name}>
            <a href={network.url} target="_blank" rel="noreferrer">
              <i className={network.className}></i>
            </a>
          </li>
        );
      });
    }

    return (
      <header id="home">
        <div className="row banner">
          <div className="banner-text">
            <h1 className="responsive-headline">{greeting}.</h1>
            <h3>
              {preoccupation} <span>{occupation}</span>{description}.
            </h3>
            <hr />
            <ul className="social">{networks}</ul>
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
